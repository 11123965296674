import React, { Suspense, useMemo } from "react";
import { Redirect, Route, useLocation } from "react-router-dom";
import AdminHeader from "./Components/AdminHeader";
import Login from "./Pages/login";
import SearchAny from "./Components/Search";
import Sidebar from "./Components/new-sidebar";
import routes from "./routes";
import ProtectedRoute from "./ProtectedRoute";
import useAuthStore from "../store/authStore";

const hideHeaderSidebarRoutes = ["/admin/daily-ops/search-firs"];

export default function AdminApp() {
  const location = useLocation();
  const userdata = useAuthStore((state) => state.userData);

  const isHideHeaderAndSidebar = useMemo(() => hideHeaderSidebarRoutes.some((el) => location.pathname?.indexOf(el) > -1), [location.pathname]);

  return (
    <>
      <Route exact path="/login" component={Login} />

      <div className="d-flex w-100">
        {!isHideHeaderAndSidebar && <Sidebar />}

        <div style={{ overflow: "auto", flexGrow: "1" }}>
          {!isHideHeaderAndSidebar && <AdminHeader />}

          <SearchAny />

          <div className="position-relative p-0 h-100">
            <Route exact path="/">
              {
                userdata?.user_id === 1014? <Redirect to="/admin/daily-ops/call-quality" exact />:<Redirect to="/admin/others/dashboard" exact />


              }
            </Route>
            <Suspense fallback={<div>Loading...</div>}>
              {routes.map((route) => (
                <ProtectedRoute key={route.path} exact {...route} />
              ))}
            </Suspense>
          </div>
        </div>
      </div>
    </>
  );
}
